import React, { useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { AuthenticationContext } from './services/context/AuthenticationContext';
import themeBase from './themeBase';
import MainLayout from './layout/MainLayout';
import PageNotFound from './components/error/PageNotFound';
import History from './components/history/History';
import Home from './components/home/Home';
import Event from './components/event/Event';
// import Client from './components/client/Client';
import AuthenticationService from './services/api/AuthenticationService';

function App() {
  const [state, setState] = useContext(AuthenticationContext);

  useEffect(() => {
    const _authenticationService = new AuthenticationService();
    const isAuthorizationStillValid = _authenticationService.checkAuthorizationValidity();
    if(isAuthorizationStillValid) {
      setState(state=> ({
        ...state,
        isAuthenticated: true
      }));
    } 
    return () => {
    }
  }, [])

  function renderRoutes(isAuthenticated) {
    if(isAuthenticated) {
      return (
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/history" element={<History/>} />
          <Route path="/event" element={<Event/>} />
          {/* <Route path="/client" element={<Client/>} /> */}
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/history" element={<History/>} />
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      );
    }
  }

  const routesEl = renderRoutes(state.isAuthenticated);

  return (
    <BrowserRouter>
      <ThemeProvider theme ={themeBase}>
        <MainLayout>
          {routesEl}
        </MainLayout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
