import React, {createContext, useState} from 'react';
import ApplicationHelper from '../helper/ApplicationHelper';

const HistoryContext = createContext([{}, () => {}]);
function HistoryProvider(props) {
    const _applicationHelper = new ApplicationHelper();
    const currentMonth = _applicationHelper.getMonthsList().find(d=> d.id === new Date().getMonth()+1);
    const currentYear = new Date().getFullYear();
    const [state, setState] = useState({
        historyMonth: currentMonth,
        historyYear: {id: currentYear, label: currentYear.toString()}
    });
    return (
        <HistoryContext.Provider value={[state, setState]}>
            {props.children}
        </HistoryContext.Provider>
    );
}

export {HistoryContext, HistoryProvider};