import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Alert, Snackbar, Typography } from '@mui/material';

function PopupNotification(prop, ref) {

    const [localState, setLocalState] = useState({
        open: false,
        severity: 'error',
        message: "",
    });

    function onCloseNotificationHandler(event) {
        setLocalState(local => ({
            ...local,
            open: !local.open
        }));
    }

    useImperativeHandle(ref, () => {
        return {
            show: (severity='info', message='') => {
                setLocalState(local => ({
                    ...local,
                    open: true,
                    severity: severity,
                    message: message,
                }));
            },
            hide: () => {
                setLocalState(local => ({
                    ...local,
                    open: false
                }));
            },
        }
    });

    function renderNotification(severity, message) {
        let newSeverity = severity;
        if(typeof severity === 'undefined' || severity === null) newSeverity = 'error';
        return (
            <Alert
                onClose={onCloseNotificationHandler}
                severity={newSeverity}
                sx={{width:"100%", backgroundColor:"#fff"}}
            >
                <Typography variant="body1">{message}</Typography>
            </Alert>
        );
    }

    const notificationEl = renderNotification(localState.severity, localState.message);

    return (
        <Snackbar
            open={localState.open}
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            onClose={onCloseNotificationHandler}
        >
            {notificationEl}
        </Snackbar>
    )
}

export default forwardRef(PopupNotification)

