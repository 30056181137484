import React, {Fragment, useContext, useEffect, useState} from 'react';
import { Container, Box, AppBar, IconButton, Button, Toolbar, Link, Drawer, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from "react-router-dom";
import styles from './header.module.css';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import NascateLogoDesktop from '../../assets/images/nascate-logo-desktop.png';
import AuthenticationService from '../../services/api/AuthenticationService';
import { AuthenticationContext } from '../../services/context/AuthenticationContext';
import { APP_COLOR_BLUE, APP_COLOR_DARK_BLUE } from '../../services/constant/ApplicationConstant';

function Header() {
    const [state, setState] = useContext(AuthenticationContext);
    const [localState, setLocalState] = useState({
        toggleMobileMenu: false,
        openLoginDialog: false,
        menuList: []
    });

    const _navigate = useNavigate();

    useEffect(() => {
        const _authenticationService = new AuthenticationService();
        
        function getMenu(isAuthenticated) {
            _authenticationService.getMenuList(isAuthenticated)
            .then(response => {
                setLocalState(local => ({
                    ...local,
                    menuList: response.data.slice()
                }));
            })
        }

        getMenu(state.isAuthenticated);
        return () => {
            
        }
    }, [state.isAuthenticated])

    function onClickStatusHandler(event) {
        event.preventDefault();
        _navigate('/home');
    }

    function onClickDynamicMenuHandler(name, url, event) {
        event.preventDefault();
        _navigate(url);
    }

    function onClickDynamicMobileMenuHandler(name, url, event) {
        event.preventDefault();
        setLocalState(local => ({
            ...local,
            toggleMobileMenu: !local.toggleMobileMenu
        }));
        _navigate(url);
    }

    function onToggleMenuHandler(args) {
        setLocalState(local => ({
            ...local,
            toggleMobileMenu: !local.toggleMobileMenu
        }));
    }
    
    function renderMenu() {
        // let loginLink = <Button id={"login-menu"} variant="text" sx={{ color: APP_COLOR_DARK_BLUE, ":hover": {color: APP_COLOR_BLUE}}} onClick={onClickLoginHandler} >Login</Button>
        // if(isAuthenticated) loginLink = <Button id={"logout-menu"} variant="text" sx={{ color: APP_COLOR_DARK_BLUE, ":hover": {color: APP_COLOR_BLUE}}} onClick={onClickLogoutHandler} >Logout</Button>
        return (
            <Fragment>
                {localState.menuList.map(menu => {
                    return (
                        <Button 
                            key={menu.id} id={`${menu.name.toLowerCase()}-menu`} 
                            variant="text" 
                            // classes={{root: styles['header-menu-button']}} 
                            sx={{
                                color: APP_COLOR_DARK_BLUE,
                                ":hover": {color: APP_COLOR_BLUE},
                            }}
                            onClick={onClickDynamicMenuHandler.bind(this, menu.name, menu.url)} 
                        >
                            {menu.name}
                        </Button>
                    );
                })}                    
                {/* <span className={styles['button-menu-separator']}></span>
                {loginLink} */}
            </Fragment>
        );
    }

    function renderMobileMenu() {
        // let loginLink = (
        //     <ListItem id={"login-item"} button disablePadding onClick={onClickLoginHandler}>
        //         <ListItemButton>
        //             <ListItemText primary={"Login"} />
        //         </ListItemButton>
        //     </ListItem>
        // );
        // if(isAuthenticated) loginLink = (
        //     <ListItem id={"logout-item"} button disablePadding onClick={onClickLogoutHandler}>
        //         <ListItemButton>
        //             <ListItemText primary={"Logout"} />
        //         </ListItemButton>
        //     </ListItem>
        // );

        return (
            <Drawer
                variant="persistent"
                anchor="right"
                open={localState.toggleMobileMenu}
                onClose={onToggleMenuHandler}
            >
                <Box
                    sx={{ width: 250}}
                    role="presentation"
                    // onClick={onToggleMenuHandler}
                    // onKeyDown={onToggleMenuHandler}
                >
                    <ListItem id={"logout-item"} button disablePadding onClick={onToggleMenuHandler} >
                        <ListItemButton>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <List>
                        {localState.menuList.map(menu => {
                        return (
                                <ListItem id={`${menu.name.toLowerCase()}-item`} key={menu.id} button disablePadding onClick={onClickDynamicMobileMenuHandler.bind(this, menu.name, menu.url)}>
                                    <ListItemButton>
                                        <ListItemText primary={menu.name} />
                                    </ListItemButton>
                                </ListItem>    
                            );
                        })}
                    </List>
                    {/* <Divider />
                    {loginLink} */}
               </Box>
            </Drawer>
        );
    }

    const menuEl = renderMenu();
    const mobileMenuEl = renderMobileMenu();

    return (
        <AppBar position="static" color="inherit" variant="outlined" >
            <Container maxWidth={"xl"}>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <div className={styles["header-banner-wrapper"]}>
                            <img className={styles["header-banner-logo"]} src={NascateLogoDesktop} alt="Nascate logo" onClick={onClickStatusHandler} />
                            <Link 
                                variant='h1' 
                                component="button" 
                                noWrap 
                                underline="none" 
                                sx={{color:APP_COLOR_DARK_BLUE, ":hover": {color: APP_COLOR_BLUE}, paddingTop:"10px"}} 
                                onClick={onClickStatusHandler} 
                            >
                                Status
                            </Link>
                        </div>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {menuEl}
                    </Box>

                    <Link 
                        variant='h1' 
                        component="button" 
                        noWrap  underline="none" 
                        sx={{  flexGrow: 1, display: { xs: 'flex', md: 'none' }, color:APP_COLOR_DARK_BLUE, ":hover": {color: APP_COLOR_BLUE} }} 
                        onClick={onClickStatusHandler}
                    >
                        Status
                    </Link>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={onToggleMenuHandler}
                        >
                            <MenuIcon />
                        </IconButton>
                        {mobileMenuEl}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header
