export const NETWORK_TIMEOUT = 180000;

export const HTTP_STATUS_OK_200 = 200;
// export const HTTP_STATUS_REDIRECTION_300 = 300;
export const HTTP_STATUS_BAD_REQUEST_400 = 400;
export const HTTP_STATUS_UNAUTHORIZED_401 = 401;
// export const HTTP_STATUS_FORBIDDEN_403 = 403;
// export const HTTP_STATUS_NOTFOUND_404 = 404;
export const HTTP_STATUS_CANCEL_REQUEST_499 = 499;
export const HTTP_STATUS_INTERNAL_SERVER_ERROR_500 = 500;
// export const HTTP_STATUS_BAD_GATEWAY_502 = 502;

export const SESSION_STORAGE_TOKEN = "jwt";
export const SESSION_STORAGE_UID = "uid";
export const SESSION_STORAGE_USERNAME = "uname";
export const SESSION_STORAGE_ROLEID = "rid";
export const SESSION_STORAGE_EXPIRATION_DATE = "xdte";
export const SESSION_STORAGE_PASSWORD_EXPIRATION_DAYS = "xdays";
export const SESSION_STORAGE_LAST_PAGE_VISITED = "lastPath";

export const PASSWORD_EXPIRATION_DAYS_BEFORE_EXPIRATION = 10;
export const PASSWORD_EXPIRATION_DAYS_FIRST_TIME_LOGIN = -1;
export const PASSWORD_EXPIRATION_DAYS_EXPIRED = 0;
export const PASSWORD_EXPIRATION_DAYS_RESET = 90;
export const PASSWORD_MINIMUM_CHARACTERS = 8;

export const APP_COLOR_DARK_BLUE = "#1d3557";
export const APP_COLOR_BLUE = "#457b9d";
export const APP_COLOR_LIGHT_BLUE = "#00a6fb";
export const APP_COLOR_LIGHT_GRAY = "#f1faee";
export const APP_COLOR_RED = "#e63946";

export const EVENT_TYPE_NO_ISSUE = 0;
export const EVENT_TYPE_NOTICE = 1;
export const EVENT_TYPE_MAINTENANCE = 2;
export const EVENT_TYPE_ISSUE = 3;
export const EVENT_TYPE_SYSTEM_UNAVAILABLE = 4;

export const EVENT_STATUS_UPCOMING = 1;
export const EVENT_STATUS_OPEN = 2;
export const EVENT_STATUS_RESOLVED = 3;

export const EVENT_TIMEZONE = "EST";
