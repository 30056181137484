import React, {createContext, useState} from 'react';

const EventContext = createContext([{}, () => {}]);
function EventProvider(props) {
    const [state, setState] = useState({
        filter:{
            eventUid: "",
            eventCaseNumber: "",
            eventTitle: "",
            eventDescription: "",
            eventDateFrom: null,
            eventDateTo: null,
            eventType: null,
            eventStatus: null,
        },

        // event: {
        //     isNewEvent: false,
        //     eventUid: "",
        //     eventCaseNumber: "",
        //     eventTitle: "",
        //     eventDescription: "",
        //     eventDate: new Date(),
        //     isScheduledEvent: false,
        //     eventType: {id: 0, label: ""},
        //     eventStatus: {id: 0, label: ""},
        //     isPublished: false,
        //     isActive: true,
        //     eventNotes: []
        // },
        isNewEvent: false,
        selectedEvents: [],
        selectedNotes: [],

        // tempNotes is used for temp storage of notes to be used for bulk insert of new event
        eventNotes: [],
        // tempNotes: [],

    });
    return (
        <EventContext.Provider value={[state, setState]}>
            {props.children}
        </EventContext.Provider>
    );
}

export {EventContext, EventProvider};