import { compareAsc } from "date-fns";
import * as ApplicationConstant from "../constant/ApplicationConstant";
import BaseWebSerivces from "./BaseWebServices";
import CommonService from "./CommonService";

export default class AuthenticationService extends BaseWebSerivces{
    constructor() {
        super();
        this._commonService = new CommonService();
    }
    // async login(username, password) {
    //     return {
    //         status: ApplicationConstant.HTTP_STATUS_OK_200,
    //         isAborted: false,
    //         hasError: false,
    //         data: {
    //             isAuthenticated: true,
    //             token: "21231212",
    //             userId: username,
    //             error: null,
    //             loading: false,
    //             authRedirectPath: "/login",
    //             pwExpirationDays: null,
    //             modules:null,
    //         }
    //     };
    // }

    getClaims(jwt) {
        const jwtArray = jwt.split(".");
        return this._commonService.decodeFromBase64(jwtArray[1]);
    };

    clearAuthenticationInfo() {
        sessionStorage.removeItem(ApplicationConstant.SESSION_STORAGE_TOKEN);
        sessionStorage.removeItem(ApplicationConstant.SESSION_STORAGE_USERNAME);
        sessionStorage.removeItem(ApplicationConstant.SESSION_STORAGE_UID);
        sessionStorage.removeItem(ApplicationConstant.SESSION_STORAGE_EXPIRATION_DATE);
        // sessionStorage.removeItem(ApplicationConstant.SESSION_STORAGE_PASSWORD_EXPIRATION_DAYS);
    };

    updateAuthenticationInfo(data) {
        if(!data) return;        
        this.clearAuthenticationInfo();  
        const claims = JSON.parse(this.getClaims(data.value));
        if(claims.expirationDays === ApplicationConstant.PASSWORD_EXPIRATION_DAYS_EXPIRED) {
            return claims;
        }
        claims.token = data.value;
        sessionStorage.setItem(ApplicationConstant.SESSION_STORAGE_TOKEN, data.value);
        sessionStorage.setItem(ApplicationConstant.SESSION_STORAGE_USERNAME, claims.username);
        sessionStorage.setItem(ApplicationConstant.SESSION_STORAGE_UID, claims.uid);
        sessionStorage.setItem(ApplicationConstant.SESSION_STORAGE_EXPIRATION_DATE, data.validTo);
        // sessionStorage.setItem(ApplicationConstant.SESSION_STORAGE_PASSWORD_EXPIRATION_DAYS, parseInt(claims.expirationDays)); 
        return claims;
    };

    login(username, password) {
        const url = encodeURI(`/token`);
        const postData = {
            "username": username.trim().toLowerCase(),
            "password": password
        }
        return super.post(url, postData)
        .then(response => {
            return response;
        });
    }

    logout() {
        this.clearAuthenticationInfo();
    }

    setAuthorizationTimeout(validityDate) {
        const expirationTime = (new Date(validityDate).getTime()- new Date().getTime());
        setTimeout(() => {
            this.logout();
        }, expirationTime);
    }

    enableRememberMe(isEnabled, username) {
        localStorage.removeItem(ApplicationConstant.SESSION_STORAGE_USERNAME); 
        if(isEnabled) localStorage.setItem(ApplicationConstant.SESSION_STORAGE_USERNAME, username);
    }

    checkAuthorizationValidity() {
        const token = sessionStorage.getItem(ApplicationConstant.SESSION_STORAGE_TOKEN);
        if(!token) {
            this.logout();
            return false;
        }
        const expirationDate = sessionStorage.getItem(ApplicationConstant.SESSION_STORAGE_EXPIRATION_DATE);
        const compareResult = compareAsc(new Date(expirationDate), new Date());
        if(compareResult < 0) {
            this.logout();
            return false;
        } else {
            this.setAuthorizationTimeout(expirationDate);
            return true;
        }
    }

    async getMenuList(isAuthenticated) {
        let menuList = [];
        if(isAuthenticated) {
            menuList = [
                {id: 1, name: "History", url: "/history", active: true},
                {id: 2, name: "Event", url: "/event", active: true},
                // {id: 3, name: "Client", url: "/client", active: true},
            ];
        } else {
            menuList = [
                {id: 1, name: "History", url: "/history", active: true},
            ];
        }

        return {
            status: ApplicationConstant.HTTP_STATUS_OK_200,
            isAborted: false,
            hasError: false,
            data: menuList.slice()
        };;
    }

}

