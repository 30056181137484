import React, {Fragment, memo, useContext, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, Button, TextField, Link, FormGroup, FormControlLabel, Switch } from '@mui/material';
import {LoadingButton} from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import LoginIcon from '@mui/icons-material/Login';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
// import { useNavigate } from 'react-router';
import { AuthenticationContext } from '../../services/context/AuthenticationContext';
import PopupNotification from '../common/notification/PopupNotification';
import AuthenticationService from '../../services/api/AuthenticationService';
import styles from './login.module.css';
import * as ApplicationConstant from '../../services/constant/ApplicationConstant';

function Login(props) {
    const [state, setState] = useContext(AuthenticationContext);
    const [localState, setLocalState] = useState({
        showLogin: true,
        dialogTitle: "Nascate Admin Login",
        username: localStorage.getItem(ApplicationConstant.SESSION_STORAGE_USERNAME)||"",
        userpassword: "",
        checkedRememberMe: localStorage.getItem(ApplicationConstant.SESSION_STORAGE_USERNAME) || false,
        showLoading: false
    });

    const _authenticationService = new AuthenticationService();
    const _notificationRef = useRef();

    function resetLocalState() {
        return {
            showLogin: true,
            dialogTitle: "Nascate Admin Login",
            username: localStorage.getItem(ApplicationConstant.SESSION_STORAGE_USERNAME)||"",
            userpassword: "",
            checkedRememberMe: false,
            showLoading: false
        }
    }

    function onChangeUsernameHandler(event) {
        setLocalState(local =>({
            ...local,
            username: event.target.value
        }));
    }

    function onChangeUserPasswordHandler(event) {
        setLocalState(local =>({
            ...local,
            userpassword: event.target.value
        }));
    }

    function onClickCloseHandler(event) {
        event.preventDefault();
        setLocalState(local => ({
            ...resetLocalState()
        }));
        props.onClose();
    }

    function onClickBackHandler(event) {
        event.preventDefault();
        setLocalState(local => ({
            ...local,
            showLogin: true,
            dialogTitle: "Nascate Admin Login"
        }));
    }

    function onSubmitLoginHandler(event) {
        event.preventDefault();
        setLocalState(local =>({
            ...local,
            showLoading: !local.showLoading
        }));
        _authenticationService.login(localState.username, localState.userpassword)
        .then(response => {
            if(response.status === ApplicationConstant.HTTP_STATUS_UNAUTHORIZED_401) {
                _authenticationService.redirectUnathorizedUser();
                return;
            } else if(response.isAborted) {
                return;
            } else if(response.hasError) {
                if(_notificationRef.current) _notificationRef.current.show("error", response.errorMessage);
                setLocalState(local => ({
                    ...local,
                    showLoading: false
                }));
            } else {
                //Save Remember Me
                
                _authenticationService.enableRememberMe(localState.checkedRememberMe, localState.username);
                _authenticationService.updateAuthenticationInfo(response.data);
                _authenticationService.setAuthorizationTimeout(response.data.validTo);
                setLocalState(local =>({
                    ...local,
                    showLogin: true,
                    showLoading: !local.showLoading,
                    username: "",
                    userpassword: ""
                }));

                setState(state => ({
                    ...state,
                    isAuthenticated: true
                }));
                props.onClose();
            }
        })
        .catch(error=>{
            if(_notificationRef.current) _notificationRef.current.show('error', "Error encountered during login");
            setLocalState(local => ({
                ...local,
                showLoading: false
            }));
        });
    }

    function onClickForgotPasswordHandler(event) {
        event.preventDefault();
        setLocalState(local => ({
            ...local,
            showLogin: false,
            dialogTitle: "Forgot Account / Password"
        }));
    }

    function onSubmitForgotAccountHandler(event) {
        event.preventDefault();
        _notificationRef.current.show('success', "Request has been successfully sent");
    }

    function onChangeRememberUsernameHandler(event) {
        setLocalState(local =>({
            ...local,
            checkedRememberMe: event.target.checked
        }));
    }

    function renderLoginView() {
        return (
            <Fragment>
                <DialogContent sx={{padding:"0 20px"}} >
                    <div className={styles["dialog-content-wrapper"]}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>                            
                                <Typography variant={"body2"} sx={{textAlign: "center"}}>
                                    This is only available to specified Nascate Admins
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    required
                                    id="username-textbox"
                                    label="Username"
                                    placeholder="Enter username"
                                    fullWidth={true}
                                    value={localState.username}
                                    onChange={onChangeUsernameHandler}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    required
                                    id="password-textbox"
                                    label="Password"
                                    placeholder="Enter password"
                                    type="password"
                                    fullWidth={true}
                                    value={localState.userpassword}
                                    onChange={onChangeUserPasswordHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormGroup>
                                    <FormControlLabel 
                                        control={
                                            <Switch
                                                id="rememberme-switch"
                                                checked={localState.checkedRememberMe}
                                                onChange={onChangeRememberUsernameHandler} 
                                            />
                                        } 
                                        label="Remember Me" 
                                    />
                                </FormGroup>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Link 
                                    variant='overline' 
                                    component="button" 
                                    noWrap  
                                    underline="always" 
                                    onClick={onClickForgotPasswordHandler} 
                                    sx={{ float: "right" }}
                                >
                                    Forgot Account &#47; Password
                                </Link>
                            </Grid> */}
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>                    
                    <LoadingButton 
                        variant='contained'
                        aria-label="login button"
                        aria-controls="dialog-login-button"
                        onClick={onSubmitLoginHandler}
                        fullWidth={true}
                        endIcon={<LoginIcon />}
                        type={"submit"}
                        loading={localState.showLoading}
                        loadingPosition='end'
                    >
                        Login
                    </LoadingButton>
                </DialogActions>
            </Fragment>
        );
    }

    function renderForgotAccountView() {
        return (
            <Fragment>
                <DialogContent>
                    <div className={styles["dialog-content-wrapper"]}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant={"body2"} sx={{textAlign: "center"}}>
                                    Please enter your registered email address: 
                                </Typography>                            
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    required
                                    id="email-textbox"
                                    label="Email address"
                                    placeholder="Enter email address"
                                    fullWidth={true}                                
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="outlined"
                        aria-label="back button"
                        aria-controls="dialog-back-button"
                        onClick={onClickBackHandler}
                        endIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                    <LoadingButton 
                        variant='contained'
                        aria-label="submit button"
                        aria-controls="dialog-submit-button"
                        onClick={onSubmitForgotAccountHandler}
                        endIcon={<SendIcon />}
                        type={"submit"}
                        loading={localState.showLoading}
                        loadingPosition='end'
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Fragment>
        );
    }

    const loginContentEl = localState.showLogin? renderLoginView() : renderForgotAccountView();


    return (
        <article>
            <Dialog 
                fullWidth= {true}
                maxWidth={"xs"}
                open={props.open}
                onClose={onClickCloseHandler}
            >
                <DialogTitle className={styles["dialog-title-default-wrapper"]}>
                    <Typography variant={"h4"} sx={{textAlign: "center"}}>
                        {localState.dialogTitle}
                    </Typography>
                    <IconButton
                        aria-label="close login dialog"
                        aria-controls="menu-appbar"
                        color="inherit"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "#999999"
                        }}
                        onClick={onClickCloseHandler}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {loginContentEl}
            </Dialog>
            <PopupNotification ref={_notificationRef} />
        </article>
    )
}


Login.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

}

export default memo(Login)