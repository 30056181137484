import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

function Spinner(props) {
    const [localState, setLocalState] = useState({
        isAriaBusy: false,
    });

    useEffect(() => {
        setLocalState(local => ({
            ...local,
            isAriaBusy: true
        }));
        return () => {}
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress
                aria-describedby = {props.ariaDescribedBy}
                aria-busy = {localState.isAriaBusy}                
            />
        </Box>
    )
}

Spinner.propTypes = {
    ariaDescribedBy: PropTypes.string,
}

export default Spinner

