import React, {lazy, memo, Suspense} from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { DashboardProvider } from '../../services/context/DashboardContext';
import Spinner from '../common/spinner/Spinner';

const Status = lazy(()=> import('./status/Status'));
const ScheduledEventList = lazy(()=> import('./scheduledevent/ScheduledEventList'));
const CurrentEventList = lazy(()=> import('./currentevent/CurrentEventList'));

function Home() {
    return (
        <DashboardProvider>
            <article>
                <Container maxWidth={"xl"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant={"h2"}>
                                Application Status
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Suspense fallback={<Spinner ariaDescribedBy="application status" />}>
                                <Status />
                            </Suspense>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Suspense fallback={<Spinner ariaDescribedBy="scheduled maintenance list" />}>
                                <ScheduledEventList />
                            </Suspense>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Suspense fallback={<Spinner ariaDescribedBy="events list" />}>
                                <CurrentEventList />
                            </Suspense>
                        </Grid>
                    </Grid>
                </Container>
            </article>
        </DashboardProvider>
    )
}

export default memo(Home)
