import React from 'react';
import { Container, Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './error.module.css'
function PageNotFound() {
    const _navigate = useNavigate();
    function  onClickHomeHandler(event) {
        event.preventDefault();
        _navigate('/home');
    }
    return (
        <article>
            <Container maxWidth={"md"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant={"h2"}>
                            Page not found
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={styles["error-pages-wrapper"]}>
                            <Typography variant={"body1"} sx={{textAlign: "center"}}>
                                The page you're looking for is not available. Go to the  <Link variant='overline' component="button" noWrap underline="always" onClick={onClickHomeHandler} >homepage</Link>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </article>
    )
}

export default PageNotFound
