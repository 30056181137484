import React, {createContext, useState} from 'react';
import * as ApplicationConstant from '../constant/ApplicationConstant';

const DashboardContext = createContext([{}, () => {}]);
function DashboardProvider(props) {
    const [state, setState] = useState({
        currentEventType: ApplicationConstant.EVENT_TYPE_NO_ISSUE,
    });
    return (
        <DashboardContext.Provider value={[state, setState]}>
            {props.children}
        </DashboardContext.Provider>
    );
}

export {DashboardContext, DashboardProvider};