import React, {createContext, useState} from 'react';

const AuthenticationContext = createContext([{}, () => {}]);
function AuthenticationProvider(props) {
    const [state, setState] = useState({
        isAuthenticated: false,
        token: null,
        userId: null,
        error: null,
        loading: false,
        authRedirectPath: "/login",
        pwExpirationDays: null,
        modules:null,
    });
    return (
        <AuthenticationContext.Provider value={[state, setState]}>
            {props.children}
        </AuthenticationContext.Provider>
    );
}

export {AuthenticationContext, AuthenticationProvider};