import React from 'react';
import styles from './layout.module.css';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

function MainLayout(props) {
    return (
        <>
            <header id={"header"} className={styles['header-wrapper']}>
                <Header />
            </header>
            <main id={"main"} className={styles['body-wrapper']}>
                {props.children}
            </main>
            <footer id={"footer"} className={styles['footer-wrapper']}>
                <Footer />
            </footer>
        </>
    )
}

export default MainLayout
