import React, {lazy, memo, Suspense} from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { EventProvider } from '../../services/context/EventContext';
import Spinner from '../common/spinner/Spinner';
const EventFilter = lazy(()=> import('./filter/EventFilter'));
const EventList = lazy(()=> import('./list/EventList'));

function Event() {
    return (
        <EventProvider>
            <article>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant={"h2"}>
                                Events
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Suspense fallback={<Spinner ariaDescribedBy="event filter content" />}>
                                <EventFilter />
                            </Suspense>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Suspense fallback={<Spinner ariaDescribedBy="event list content" />}>
                                <EventList />
                            </Suspense>
                        </Grid>
                    </Grid>
                </Container>
            </article>
        </EventProvider>
    )
}

export default memo(Event)
