import Axios from "axios";
import * as ApplicationConstant from "../constant/ApplicationConstant";

export default class BaseWebServices {
    constructor(version = "1.0") {
        const baseUrl = `${process.env.REACT_APP_API_URL_BASE}/v${version}`;
        this._nascateWebApi = new Axios.create({
            baseURL: baseUrl,
            timeout: ApplicationConstant.NETWORK_TIMEOUT,
            headers: {
                "Content-Type": "application/json",
            }
        });

        this._nascateWebApi.interceptors.request.use( request => {
            const token = sessionStorage.getItem(ApplicationConstant.SESSION_STORAGE_TOKEN);
            if(token) {
                request.headers["Authorization"] = "Bearer " + token;
            }
        
            return request;
        }, error=> {
            return Promise.reject(error);
        });
        
        this._nascateWebApi.interceptors.response.use(response => {
            return response;
        }, error=> {
            return Promise.reject(error);            
        });
        
        this._cancelToken = Axios.CancelToken;
        this._cancelTokenSource = this._cancelToken.source();
    }

    abortRequest() {
        if(this._cancelTokenSource) {
            this._cancelTokenSource.cancel("Request cancelled by the user");
        }
    }

    get(url, customFields) {
        let temp = {
            hasError: false,
            isAborted: false,
            successMessage: "",
            errorMessage: "",
            data: {},
            status: ApplicationConstant.HTTP_STATUS_BAD_REQUEST_400,
            resultRecords: 0,
            totalRecords: 0
        };

        return this._nascateWebApi.get(url,  {cancelToken: this._cancelTokenSource.token})
        .then(response=> {
            temp.hasError = false;
            temp.status = response.status;
            if(response.data.success !== null && response.data.success !== undefined) {            
                if(response.data.success) {
                    if(customFields) {
                        customFields.forEach(f=> {
                            temp.data[f] = response.data[f];
                        });
                        temp.data.data = response.data.data;
                    } else {
                        temp.data = response.data.data;
                    }
                    temp.successMessage = response.data.message;
                    temp.resultRecords = response.data.resultRecords;
                    temp.totalRecords = response.data.totalRecords;
                } else {
                    temp.hasError = true;
                    temp.errorMessage = response.data.message;
                }
            } else {
                if(response.data.data) {
                    temp.data = response.data.data;
                } else {
                    temp.data = response.data;
                }
            }
            return temp;
        })
        .catch(ex => {
            temp.hasError = true;

            if(Axios.isCancel(ex)){
                temp.isAborted = true;
                temp.errorMessage = ex.message;
                temp.status = ApplicationConstant.HTTP_STATUS_CANCEL_REQUEST_499
            } else  {
                temp.data = ex.response.data;
                temp.status = ex.response.status;
            }
            return temp;
        });
    }

    post(url, postData, customFields) {
        let temp = {
            hasError: false,
            isAborted: false,
            successMessage: "",
            errorMessage: "",
            data: {},
            status: ApplicationConstant.HTTP_STATUS_BAD_REQUEST_400,
            resultRecords: 0,
            totalRecords: 0
        };

        return this._nascateWebApi.post(url, postData,  {cancelToken: this._cancelTokenSource.token})        
        .then(response=> {
            temp.hasError = false;
            temp.status = response.status;
            
            if(response.data.success !== null && response.data.success !== undefined) {
                if(response.data.success) {
                    if(customFields) {
                        customFields.forEach(f=> {
                            temp.data[f] = response.data[f];
                        });
                        temp.data.data = response.data.data;
                    } else {
                        temp.data = response.data.data;
                    }
                    temp.successMessage = response.data.message;
                    temp.resultRecords = response.data.resultRecords;
                    temp.totalRecords = response.data.totalRecords;
                } else if(response.data.success === true) {
                    // this is for MFA api response only
                    temp.data= {...response.data};
                } else {
                    temp.hasError = true;
                    temp.errorMessage = response.data.message;
                }
            } else {
                if(response.data.data) {
                    temp.data = response.data.data;
                } else {
                    temp.data = response.data;
                }
            }
            return temp;
        })
        .catch(ex => {
            temp.hasError = true;
            if(Axios.isCancel(ex)){
                temp.isAborted = true;
                temp.errorMessage = ex.message;
                temp.status = ApplicationConstant.HTTP_STATUS_CANCEL_REQUEST_499
            } else  {
                temp.data = ex.response.data;
                temp.errorMessage = ex.message;
                temp.status = ex.response.status;
            }
            return temp;
        });
    }

    put(url, postData, customFields) {
        let temp = {
            hasError: false,
            isAborted: false,
            successMessage: "",
            errorMessage: "",
            data: null,
            status: ApplicationConstant.HTTP_STATUS_BAD_REQUEST_400,
            resultRecords: 0,
            totalRecords: 0
        };

        return this._nascateWebApi.put(url, postData,  {cancelToken: this._cancelTokenSource.token})
        .then(response=> {
            temp.hasError = false;
            temp.status = response.status;

            if(response.data.success !== null && response.data.success !== undefined) {                        
                if(response.data.success) {
                    if(customFields) {
                        customFields.forEach(f=> {
                            temp.data[f] = response.data[f];
                        });
                        temp.data.data = response.data.data;
                    } else {
                        temp.data = response.data.data;
                    }
                    temp.successMessage = response.data.message;
                    temp.resultRecords = response.data.resultRecords;
                    temp.totalRecords = response.data.totalRecords;
                } else if(response.data.success === true) {
                    // this is for MFA api response only
                    temp.data= {...response.data};
                } else {
                    temp.hasError = true;
                    temp.errorMessage = response.data.message;
                }
            } else {
                if(response.data.data) {
                    temp.data = response.data.data;
                } else {
                    temp.data = response.data;
                }
            }
            return temp;
        })
        .catch(ex => {
            temp.hasError = true;

            if(Axios.isCancel(ex)){
                temp.isAborted = true;
                temp.errorMessage = ex.message;
                temp.status = ApplicationConstant.HTTP_STATUS_CANCEL_REQUEST_499
            } else  {
                temp.data = ex.response.data;
                temp.errorMessage = ex.message;
                temp.status = ex.response.status;
            }
            return temp;
        });
    }

    delete(url, inputData, customFields) {
        let temp = {
            hasError: false,
            isAborted: false,
            successMessage: "",
            errorMessage: "",
            data: null,
            status: ApplicationConstant.HTTP_STATUS_BAD_REQUEST_400
        };

        return this._nascateWebApi.delete(url, {data: inputData, cancelToken: this._cancelTokenSource.token})        
        .then(response=> {
            temp.hasError = false;
            temp.status = response.status;

            if(response.data.success !== null && response.data.success !== undefined) {
                if(response.data.success) {
                    if(response.data.totalRecords > 0) {
                        if(customFields) {
                            customFields.forEach(f=> {
                                temp.data[f] = response.data[f];
                            });
                            temp.data.data = response.data.data;

                        } else {
                            temp.data = response.data.data;
                        }
                        temp.successMessage = response.data.message;
                    }
                } else {
                    temp.hasError = true;
                    temp.errorMessage = response.data.message;
                }
            } else {
                if(response.data.data) {
                    temp.data = response.data.data;
                } else {
                    temp.data = response.data;
                }
            }
            return temp;
        })
        .catch(ex => {
            temp.hasError = true;

            if(Axios.isCancel(ex)){
                temp.isAborted = true;
                temp.errorMessage = ex.message;
                temp.status = ApplicationConstant.HTTP_STATUS_CANCEL_REQUEST_499
            } else  {
                temp.data = ex.response.data;
                temp.errorMessage = ex.message;
                temp.status = ex.response.status;
            }
            return temp;
        });
    }

}