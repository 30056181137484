import React, { useContext, useState } from 'react';
import { AppBar, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { AuthenticationContext } from '../../services/context/AuthenticationContext';
import AuthenticationService from '../../services/api/AuthenticationService';
import Login from '../login/Login';

function Footer() {
    const [state, setState] = useContext(AuthenticationContext);
    const [localState, setLocalState] = useState({
        openLoginDialog: false,
    });

    const _authenticationService = new AuthenticationService();

    const _navigate = useNavigate();
    const _currentDate = new Date();


    function onClickLoginHandler(event) {
        event.preventDefault();
        setLocalState(local => ({
            ...local,
            openLoginDialog: !local.openLoginDialog,
        }));
    }

    function onClickLogoutHandler(event) {
        event.preventDefault();
        _authenticationService.logout();
        setLocalState(local => ({
            ...local,
            toggleMobileMenu: !local.toggleMobileMenu
        }));
        setState(state=> ({
            ...state,
            isAuthenticated: false
        }));
        _navigate('/home');
    }

    function onClickCloseDialogHandler() {
        setLocalState(local => ({
            ...local,
            openLoginDialog: !local.openLoginDialog,
        }));
    }

    function renderLoginLink(isAuthenticated) {
        let loginLink = <Button id={"login-menu"} variant="text" onClick={onClickLoginHandler} >Admin Login</Button>
        if(isAuthenticated) loginLink = <Button id={"logout-menu"} variant="text" onClick={onClickLogoutHandler} >Admin Logout</Button>
        return loginLink;
    }

    const loginLinkEl = renderLoginLink(state.isAuthenticated);

    return (
        <AppBar 
            position="static" 
            // color="secondary" 
            variant="outlined" 
            sx={{ 
                top: 'auto', 
                bottom: 0, 
                marginTop:"20px",
                backgroundColor: "#000000"
            }}
        >
            <Container maxWidth={"xl"} >
                <Login 
                    open={localState.openLoginDialog} 
                    onClose={onClickCloseDialogHandler}
                />
                <Typography variant="body1" sx={{color: "#ffffff", textAlign: "center", padding: "15px 0"}}>
                    &#169; {_currentDate.getFullYear()} Nascate | All Rights Reserved | {loginLinkEl}
                    {/* | <Link variant="body1" component="a" underline="none" sx={{color:"#fff"}} href="https://www.nascate.com/privacy-policy" target="_blank">Privacy Policy</Link> */}
                </Typography>
            </Container>
        </AppBar>
    )
}

export default Footer
