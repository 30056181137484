import { createTheme } from '@mui/material/styles';

const themeBase = createTheme({
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        h1: {            
            color: "#1d3557",
            fontWeight: 400,
            '@media (max-width:599px)': {
                fontSize: '1.875rem',
            },
            '@media (min-width:600px)': {
                fontSize: '1.875rem',
            },
            '@media (min-width:900px)': {
                fontSize: '2.0rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '2.0rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '2.25rem',
            },
        },
        h2: {
            textAlign: "center",
            color: "#1d3557",
            fontWeight: 400,
            '@media (max-width:599px)': {
                padding: '10px 0',
                fontSize: '1.75rem',
            },
            '@media (min-width:600px)': {
                padding: '10px 0',
                fontSize: '1.75rem',
            },
            '@media (min-width:900px)': {
                padding: '20px 0',
                fontSize: '1.875rem',
            },
            '@media (min-width:1200px)': {
                padding: '20px 0',
                fontSize: '1.875rem',
            },
            '@media (min-width:1536px)': {
                padding: '20px 0',
                fontSize: '2.0rem',
            },
        },
        h3: {
            color: "#1d3557",
            fontWeight: 400,
            '@media (max-width:599px)': {
                fontSize: '1.375rem',
            },
            '@media (min-width:600px)': {
                fontSize: '1.375rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1.5rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '1.5rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '1.5rem',
            },
        },
        h4: {
            color: "#000000",
            fontWeight: 400,
            '@media (max-width:599px)': {
                fontSize: '1.125rem',
            },
            '@media (min-width:600px)': {
                fontSize: '1.125rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1.25rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '1.25rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '1.25rem',
            },
        },
        h5: {
            color: "#000000",
            fontWeight: 400,
            display: "inline",
            '@media (max-width:599px)': {
                fontSize: '1.0rem',
            },
            '@media (min-width:600px)': {
                fontSize: '1.0rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1.125rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '1.125rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '1.125rem',
            },
        },
        h6: {
            color: "#000000",
            fontWeight: 400,
            display: "inline",
            '@media (max-width:599px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:600px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1.0rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '1.0rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '1.0rem',
            },
        },
        subtitle1: {
            color: "#000000",
            fontWeight: 700,
            '@media (max-width:599px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:600px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:900px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '0.875rem',
            },
        },
        subtitle2: {
            color: "#9b9b9b",
            fontWeight: 700,
            '@media (max-width:599px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:600px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:900px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '0.875rem',
            },
        },  
        body1: {
            color: "#000000",
            '@media (max-width:599px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:600px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:900px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '0.875rem',
            },
        },
        body2: {
            color: "#9b9b9b",
            '@media (max-width:599px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:600px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:900px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '0.875rem',
            },
        },
        button: {
            fontSize: "0.875rem",
            fontWeight: "bold",
            textTransform: "none"
        },
        caption: {

        },
        overline: {
            textTransform: "none",
            letterSpacing: '0.01071em',            
            '@media (max-width:599px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:600px)': {
                fontSize: '0.75rem',
            },
            '@media (min-width:900px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1200px)': {
                fontSize: '0.875rem',
            },
            '@media (min-width:1536px)': {
                fontSize: '0.875rem',
            },
        }

    }
});

export default themeBase;
