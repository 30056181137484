import * as ApplicationConstant from "../constant/ApplicationConstant";
import BaseWebSerivces from "./BaseWebServices";

export default class CommonService extends BaseWebSerivces{

    encodeToBase64(input) {
        return window.btoa(input);
    }
    
    decodeFromBase64(input) {
        return window.atob(input);
    }

    getDropdownListSource(url, body) {
        if(typeof body === 'undefined') {
            return super.get(url)
            .then(response => {
                return response;
            });
        } else {
            return super.post(url, body)
            .then(response => {
                return response;
            });
        }
        
    }
}

